<template>
  <div class="empty-cart">
    <Icon
      name="material-symbols:shopping-bag-outline"
      size="48"
    />

    <strong>{{ $t('cart.components.empty.cart_empty') }}</strong>
    <NuxtLink
      v-if="shopBaseUrl"
      :to="shopBaseUrl"
      class="button button--icon-left"
    >
      <Icon name="material-symbols:shopping-bag-outline" />
      {{ $t('cart.components.form.buttons.go_shopping') }}
    </NuxtLink>
    <NuxtLink
      v-if="shopBaseUrl"
      :to="`${shopBaseUrl}/customer/account`"
    >
      {{ $t('cart.components.form.buttons.create_account_or_login') }}
    </NuxtLink>
  </div>
</template>

<script setup>
  import { onMounted } from "vue";
  
  const storeCode = useStoreCode();
  const runtimeConfig = useRuntimeConfig();
  const shopBaseUrl = useState('shopBaseUrl', () => null);
  
  onMounted(() => {
    if (runtimeConfig?.public?.SHOP?.BASE_URL && storeCode?.value) {
      shopBaseUrl.value = runtimeConfig.public.SHOP.BASE_URL.replace('store_code', storeCode.value);
    }
  })
</script>

<style lang="scss">
  .empty-cart {
    margin: 0 auto;
    display: grid;
    text-align: center;
    grid-row-gap: 30px;
    grid-column-end: span 2;

    svg {
      margin: 0 auto;
    }

    h1 {
      margin: 30px 0 0;
    }

    h2 {
      text-transform: uppercase;
      font-family: 'heroic', sans-serif;
      margin: 15px 0 30px;
    }

    img {
      margin-bottom: 30px;
    }

    strong {
      line-height: 1.5;
      font-size: 1.2rem;
    }

    .button {
      width: auto;
      margin: 0 auto;
      min-width: 220px;
    }

    a:not(.button) {
      color: var(--primary, $primary);
    }
  }
</style>
